<script>
import useVuelidate from '@vuelidate/core'
import {maxLength, required} from '@vuelidate/validators'

/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkFormSubmitted from "@components/general/AkFormSubmitted";
import AkDropdown from "@components/input/AkDropdown";
import AkInputTextArea from "@components/input/AkInputTextArea";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import bilanCriseService from "@services/bilanCriseService";
import agerService from "@services/agerService";
import uerService from "@services/uerService";
import ceiService from "@services/ceiService";
import bilanCrisePeriodService from "@services/bilanCrisePeriodService";
import rolePermissionService from "@services/rolePermissionService";

export default {
  components: {AkFormView, AkFormSubmitted, AkDropdown, AkInputTextArea},
  mixins: [randomRef, roleMixin],
  setup() {
    return {v$: useVuelidate()}
  },
  metaInfo() {
    return {
      title: "bilan_crise.create",
    }
  },
  data() {
    return {
      submitted: false,
      agerList: [],
      uerList: [],
      ceiList: [],
      crisePeriodList: [],
      alreadyExists: false,
      disableAger: null,
      disableUer: null,
      disableCei: null,
      current: {
        ceiId: null,
        uerId: null,
        agerId: null,
      }
    }
  },
  validations() {
    return {
      current: {
        agerId: {required},
        uerId: {required},
        ceiId: {required},
        crisePeriodId: {required},
        issue: {maxLength: maxLength(1000)},
        solution: {maxLength: maxLength(1000)},
      }
    }
  },
  mounted() {
    this.getRef().showTotalLoader();

    let p1 = agerService.findAllCurrent();
    p1.then(data => this.agerList = data);
  
    let p2 = uerService.findAllCurrent();
    p2.then(data => this.uerList = data);

    let p3 = ceiService.findAllCurrent();
    p3.then(data => this.ceiList = data);
  
    let p4 = bilanCrisePeriodService.findAllCurrent();
    p4.then(data => this.crisePeriodList = data);
  
    let p5 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.crisisReview.edit);
  
    Promise.all([p1, p2, p3, p4, p5]).then(()=>{
      this.initData();
      this.getRef().hideLoader();
    });
  },
  methods: {
    initData() {
      if (this.currentUser.agerId) {
        this.disableAger = true;
        this.current.agerId = this.currentUser.agerId;
      }
      if (this.currentUser.uerId) {
        this.disableUer = true;
        this.current.uerId = this.currentUser.uerId;
      }
      if (this.currentUser.ceiId) {
        this.disableCei = true;
        this.current.ceiId = this.currentUser.ceiId;
      }
    },
    create() {
      if (this.alreadyExists) return;
      if (!this.checkForm()) {
        this.getRef().error(this.$t("bilan.error.create"));
        return;
      }

      this.getRef().showLoader(this.$refs.form)
      bilanCriseService.create(this.current).then(data => {
        let msg = this.$t("bilan_crise.created");
        this.$router.replace({ path: `/bilan/crise/${data.id}/details`, query: { msg: msg } });
      }).catch(e=>{
          this.getRef().hideLoader();
          this.getRef().error(this.$t("error."+e.response.data.error));
          this.submitted = false;
        }
      );
    },
    checkForm() {
      this.getRef().resetMessages();
      this.submitted = true;
      this.v$.$touch();
      return !this.v$.$error; 
    },
    testExistence() {
      this.getRef().resetMessages();
      if (this.current.ceiId && this.current.crisePeriodId) {
        bilanCriseService.findByCeiIdAndCrisePeriodId(this.current.ceiId, this.current.crisePeriodId).then(data => {
          this.alreadyExists = !!data;
          if (this.alreadyExists) this.getRef().error("Cette crise existe déjà pour ce CEI et cette période de crise");
        });
      }
    }
  },
  computed: {
    uerListForCurrent() {
      return this.uerList.filter(uer => uer.agerId === this.current.agerId);
    },
    ceiListForCurrent() {
      return this.ceiList.filter(cei => cei.uerId === this.current.uerId);
    }
  },
  watch: {
    uerListForCurrent() {
      if(!this.uerListForCurrent.some(uer => uer.id===this.current.uerId))
        this.current.uerId = undefined;
    },
    ceiListForCurrent() {
      if(!this.ceiListForCurrent.some(cei => cei.id===this.current.ceiId))
        this.current.ceiId = undefined;
    },
    'current.ceiId'() {
      this.alreadyExists = false;
      this.testExistence();
    },
    'current.crisePeriodId'() {
      this.alreadyExists = false;
      this.testExistence();
    }
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('bilan_crise.create')">
    <template v-slot:form>
      <AkFormSubmitted :submitted=submitted reference="form">
        <div class="form-row">
          <AkDropdown :label="$t('ager_label')"
                      v-model="current.agerId"
                      :validator="v$.current.agerId"
                      :submitted=this.submitted
                      :options=this.agerList
                      :disabled=disableAger
                      option-value="id"
                      option-label="label"
                      class-name="col-md-4"/>
          <AkDropdown :label="$t('uer_label')"
                      v-if="current.agerId"
                      v-model="current.uerId"
                      :validator="v$.current.uerId"
                      :submitted=this.submitted
                      :options=this.uerListForCurrent
                      :disabled=disableUer
                      option-value="id"
                      option-label="label"
                      class-name="col-md-4"/>
          <AkDropdown :label="$t('cei_label')"
                      v-if="current.uerId"
                      v-model="current.ceiId"
                      :validator="v$.current.ceiId"
                      :submitted=this.submitted
                      :options=this.ceiListForCurrent
                      :disabled=disableCei
                      option-value="id"
                      option-label="label"
                      class-name="col-md-4"/>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('bilan_crise.period')"
                      v-model="current.crisePeriodId"
                      :validator="v$.current.crisePeriodId"
                      :submitted=this.submitted
                      :options=this.crisePeriodList
                      option-value="id"
                      option-label="name"
                      class-name="col-md-4"/>
        </div>
        <h5>{{ $t('bilan_crise.input_text_area_title') }}</h5>
        <div class="form-row">
          <AkInputTextArea
              :rows=5
              v-model="current.issue"
              :validator="v$.current.issue"
              :submitted=this.submitted
              :label="$t('bilan_crise.issue')"
              class-name="col-md-6"/>
          <AkInputTextArea
              :rows=5
              v-model="current.solution"
              :validator="v$.current.solution"
              :submitted=this.submitted
              :label="$t('bilan_crise.solution')"
              class-name="col-md-6"/>
        </div>
        <div class="float-right">
          <button v-if="!alreadyExists && this.canCreateCrisis()" @click=create() class="btn btn-primary" style="margin-left: 10px">{{ $t('save') }}</button>
        </div>
      </AkFormSubmitted>
    </template>
  </AkFormView>
</template>